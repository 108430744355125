/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import styles from "./styles.module.scss";
import Image from "next/image";
import SVG from "react-inlinesvg";
import DetailModel from "./DetailModel";
import { truncateString } from "../../Configs/TruncateString";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { isSafari, browserName } from "react-device-detect";
const Index = (props) => {
  const [showDetialModel, setDetailModel] = useState(false);
  const [selectedImagestate, setSelectedImagestate] = useState();
  const [loadFirstTime, setLoadFirstTime] = useState(true);
  const [selectedImageTitle, setselectedImageTitle] = useState();

  React.useEffect(() => {
    if (props.item) {
      setSelectedImagestate(props.item[0]);
    }
  }, [props.item]);
  const hiddenAudioInputDetailsPage = React.useRef(null);
  const [playing, setPlaying] = useState(false);
  const onhandleChange = () => {
    if (hiddenAudioInputDetailsPage.current) {
      if (playing === true) {
        hiddenAudioInputDetailsPage.current.load();
        hiddenAudioInputDetailsPage.current.play();
      } else {
        hiddenAudioInputDetailsPage.current.pause();
        hiddenAudioInputDetailsPage.current.load();
      }
    }
  };
  const handlePlayOption = () => {
    setPlaying(!playing);
  };
  useEffect(() => {
    playing === true ? onhandleChange() : onhandleChange();
  }, [playing]);
  useEffect(() => {
    if (loadFirstTime) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  }, [selectedImagestate]);
  useEffect(() => {
    if (loadFirstTime) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  }, []);
  //  Audio control end
  const handleChangePlayoption = (data) => {
    if (selectedImagestate !== data) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  };
  const handleChnagedData = (data) => {
    setSelectedImagestate(data);
    setPlaying(!playing);
    setLoadFirstTime(false);
    if (data?.file_type?.includes("audio/") && selectedImagestate !== data) {
      // handleChangePlayoption(data);
      // setPlaying(true);
    }
    // handleChangePlayoption();
  };
  return (
    <div>
      {selectedImagestate?.file_type?.includes("audio/") ? (
        <div className={`${styles.audioImage} ${styles.imagecontainer}`}>
          {!isSafari && browserName !== "Safari" && browserName !== "none" ? (
            <Image
              src={`${
                props.currentObj?.thumbnail
                  ? props.currentObj?.thumbnail?.home_page_listings
                  : "/assets/img/image-loader.gif"
              }`}
              alt={`${props.image ? props.image.alt_text : ""}`}
              onClick={() => {
                handlePlayOption();
              }}
              className={`${styles.asset} `}
              height="340px"
              width="550px"
            />
          ) : (
            <img
              src={`${
                props.currentObj?.thumbnail
                  ? props.currentObj?.thumbnail?.original
                  : "/assets/img/image-loader.gif"
              }`}
              alt={`${props.image ? props.image.alt_text : ""}`}
              onClick={() => {
                handlePlayOption();
              }}
              className={`${styles.asset} `}
              height="340px"
              width="550px"
              
            />
          )}
          {/* {selectedImagestate?.title !== null && selectedImagestate?.title !== "" && (
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip
                  id={`tooltip-${selectedImagestate?.title}`}
                  style={{ maxWidth: "300px", width: "auto" }}
                >
                  {selectedImagestate?.title}.
                </Tooltip>
              }
            >
              <div className={`${styles.title}`}>
                <SVG
              src={
                playing === false
                  ? "/assets/vectors/fi_play.svg"
                  : "/assets/vectors/fi_pause.svg"
              }
              width="33px"
              height="36px"
            />
              </div>
            </OverlayTrigger>
          )} */}
          <div
            className={`${styles.playAudioSvg}`}
            onClick={() => {
              handlePlayOption();
            }}
          >
            {/* <SVG
              src={
                playing === false
                  ? "/assets/vectors/fi_play.svg"
                  : "/assets/vectors/fi_pause.svg"
              }
              width="33px"
              height="36px"
            /> */}
            {selectedImagestate?.title !== null &&
            selectedImagestate?.title !== "" ? (
              <OverlayTrigger
                placement={"right"}
                overlay={
                  <Tooltip
                    id={`tooltip-${selectedImagestate?.title}`}
                    style={{ maxWidth: "300px", width: "auto" }}
                  >
                    {selectedImagestate?.title}.
                  </Tooltip>
                }
              >
                <div className={``}>
                  <SVG
                    src={
                      playing === false
                        ? "/assets/vectors/fi_play.svg"
                        : "/assets/vectors/fi_pause.svg"
                    }
                    width="33px"
                    height="36px"
                  />
                </div>
              </OverlayTrigger>
            ) : (
              <SVG
                src={
                  playing === false
                    ? "/assets/vectors/fi_play.svg"
                    : "/assets/vectors/fi_pause.svg"
                }
                width="33px"
                height="36px"
              />
            )}
          </div>
          <audio
            ref={hiddenAudioInputDetailsPage}
            style={{ display: "none" }}
            controls
            className={`${styles.audiosetting}`}
            // autoPlay
            // loop
            id={`abc`}
          >
            <source
              src={`${
                selectedImagestate && selectedImagestate.src?.home_page_listings
              }`}
              // autoPlay
              type={`audio/${
                selectedImagestate &&
                selectedImagestate?.src?.home_page_listings?.split(".").pop()
              }`}
            ></source>
          </audio>
        </div>
      ) : selectedImagestate?.file_type?.includes("video/") ? (
        <>
          {/* <p className={`${styles.videoTitle}`}>{props.currentObj.title}</p> */}

          {selectedImagestate?.title !== null &&
          selectedImagestate?.title !== "" ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip
                  id={`tooltip-${selectedImagestate?.title}`}
                  style={{ maxWidth: "300px", width: "auto" }}
                >
                  {selectedImagestate?.title}.
                </Tooltip>
              }
            >
              <div
                className={`${styles.videoWrapper} ${styles.imagecontainer}`}
              >
                <video
                  src={`${
                    selectedImagestate &&
                    selectedImagestate.src?.home_page_listings
                  }`}
                  controls
                  width="470px"
                  height="370px"
                  playsInline
                  autoPlay
                  loop
                  controlsList="nodownload"
                />{" "}
              </div>
            </OverlayTrigger>
          ) : (
            <div className={`${styles.videoWrapper} ${styles.imagecontainer}`}>
              <video
                src={`${
                  selectedImagestate &&
                  selectedImagestate.src?.home_page_listings
                }`}
                controls
                width="470px"
                height="370px"
                playsInline
                autoPlay
                loop
                controlsList="nodownload"
              />
            </div>
          )}
          {/* {selectedImagestate?.title !== null && selectedImagestate?.title !== "" && (
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip
                  id={`tooltip-${selectedImagestate?.title}`}
                  style={{ maxWidth: "300px", width: "auto" }}
                >
                  {selectedImagestate?.title}.
                </Tooltip>
              }
            >
              <div className={`${styles.title}`}>
                {truncateString(selectedImagestate?.title ?? "", 30)}{" "}
              </div>
            </OverlayTrigger>
          )} */}
        </>
      ) : !selectedImagestate?.file_type.includes("pinata") ? (
        <>
          {selectedImagestate?.title !== null &&
          selectedImagestate?.title !== "" ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip
                  id={`tooltip-${selectedImagestate?.title}`}
                  style={{ maxWidth: "300px", width: "auto" }}
                >
                  {selectedImagestate?.title}.
                </Tooltip>
              }
            >
              <div className={`${styles.imagecontainer}`}>
                {!isSafari &&
                browserName !== "Safari" &&
                browserName !== "none" ? (
                  <Image
                    src={
                      selectedImagestate?.src?.home_page_listings ??
                      "/assets/img/image-loader.gif"
                    }
                    onClick={() => {
                      setDetailModel(true);
                      setselectedImageTitle(selectedImagestate?.title);
                    }}
                    alt={selectedImagestate?.alt_text}
                    height="340px"
                    width="550px"
                  />
                ) : (
                  <img
                    src={
                      selectedImagestate?.src?.original ??
                      "/assets/img/image-loader.gif"
                    }
                    onClick={() => {
                      setDetailModel(true);
                      setselectedImageTitle(selectedImagestate?.title);
                    }}
                    alt={selectedImagestate?.alt_text}
                    height="340px"
                    width="550px"
                    
                  />
                )}
              </div>
            </OverlayTrigger>
          ) : (
            <div className={`${styles.imagecontainer}`}>
              <Image
                src={
                  selectedImagestate?.src?.home_page_listings ??
                  "/assets/img/image-loader.gif"
                }
                onClick={() => {
                  setDetailModel(true);
                  setselectedImageTitle(selectedImagestate?.title);
                }}
                alt={selectedImagestate?.alt_text}
                height="340px"
                width="550px"
              />
            </div>
          )}
          {/* {selectedImagestate?.title !== null && selectedImagestate?.title !== "" && (
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip
                      id={`tooltip-${selectedImagestate?.title}`}
                      style={{ maxWidth: "300px", width: "auto" }}
                    >
                      {selectedImagestate?.title}.
                    </Tooltip>
                  }
                >
                  <div className={`${styles.title}`}>
                    {truncateString(selectedImagestate?.title ?? "", 30)}{" "}
                  </div>
                </OverlayTrigger>
              )} */}
        </>
      ) : (
        <>
          {selectedImagestate?.title !== null &&
          selectedImagestate?.title !== "" ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip
                  id={`tooltip-${selectedImagestate?.title}`}
                  style={{ maxWidth: "300px", width: "auto" }}
                >
                  {selectedImagestate?.title}.
                </Tooltip>
              }
            >
              <div className={`${styles.imagecontainer}`}>
                {!isSafari &&
                browserName !== "Safari" &&
                browserName !== "none" ? (
                  <Image
                    src={
                      selectedImagestate?.src?.home_page_listings ??
                      "/assets/img/image-loader.gif"
                    }
                    onClick={() => {
                      setDetailModel(true);
                      setselectedImageTitle(selectedImagestate?.title);
                    }}
                    alt={selectedImagestate?.alt_text}
                    height="340px"
                    width="550px"
                  />
                ) : (
                  <img
                    src={
                      selectedImagestate?.src?.original ??
                      "/assets/img/image-loader.gif"
                    }
                    onClick={() => {
                      setDetailModel(true);
                      setselectedImageTitle(selectedImagestate?.title);
                    }}
                    alt={selectedImagestate?.alt_text}
                    height="340px"
                    width="550px"
                    
                  />
                )}
              </div>
            </OverlayTrigger>
          ) : (
            <div className={`${styles.imagecontainer}`}>
              {!isSafari &&
              browserName !== "Safari" &&
              browserName !== "none" ? (
                <Image
                  src={
                    selectedImagestate?.src?.home_page_listings ??
                    "/assets/img/image-loader.gif"
                  }
                  onClick={() => {
                    setDetailModel(true);
                    setselectedImageTitle(selectedImagestate?.title);
                  }}
                  alt={selectedImagestate?.alt_text}
                  height="340px"
                  width="550px"
                />
              ) : (
                <img
                  src={
                    selectedImagestate?.src?.original ??
                    "/assets/img/image-loader.gif"
                  }
                  onClick={() => {
                    setDetailModel(true);
                    setselectedImageTitle(selectedImagestate?.title);
                  }}
                  alt={selectedImagestate?.alt_text}
                  height="340px"
                  width="550px"
                  
                />
              )}
            </div>
          )}

          {/* {selectedImagestate?.title !== null && selectedImagestate?.title !== "" && (
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip
                      id={`tooltip-${selectedImagestate?.title}`}
                      style={{ maxWidth: "300px", width: "auto" }}
                    >
                      {selectedImagestate?.title}.
                    </Tooltip>
                  }
                >
                  <div className={`${styles.title}`}>
                    {truncateString(selectedImagestate?.title ?? "", 30)}{" "}
                  </div>
                </OverlayTrigger>
              )} */}
        </>
      )}
      {props?.item?.length > 1 && props?.showSlider !== "no" ? (
        <div className={styles.sliderMAindiv}>
          <Slider
            item={props?.item}
            currentObj={props?.currentObj}
            setSelectedImagestate={(data) => {
              handleChnagedData(data);
            }}
            setPlaying={(data) => {
              setPlaying(data);
            }}
            selectedImagestate={selectedImagestate}
            playing={playing}
          />
        </div>
      ) : null}
      <DetailModel
        modalStatus={showDetialModel}
        handleClose={() => {
          setDetailModel(false);
        }}
        handleOpen={() => {
          setDetailModel(true);
        }}
        selectedImagestate={selectedImagestate}
        handlePlayOption={handlePlayOption}
        currentObj={props.currentObj}
        title={selectedImageTitle}
      />
    </div>
  );
};

export default Index;
