import React from "react";
import { Modal } from "react-bootstrap";

const DetailModel = (props) => {
  return (
    <div>
      <Modal
        fullscreen={true}
        show={props.modalStatus}
        onHide={() => {
          props.handleClose();
        }}
        onBackdropClick={() => {
          props.handleClose();
        }}
        // onEscapeKeyDown={}
        backdrop={true}
        className={`NFTDetailsModel`}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="mainContainer">
            <div className="Imagecontainer">
              <div className={`imagecontainerDilog`}>
                <img
                  className="img-fluid"
                  src={
                    props?.selectedImagestate?.src?.home_page_listings ??
                    "/assets/img/image-loader.gif"
                  }
                  alt={props?.selectedImagestate?.alt_text}
                  // layout="intrinsic"
                  // width={100}
                  // height={100}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DetailModel;
