import React from "react";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import styles from "./styles.module.scss";
import { truncateString } from "../../Configs/TruncateString";
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { isSafari, browserName } from "react-device-detect";
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);
const Slider = (props) => {
  const leftArrow = React.useRef(null);
  const rightArrow = React.useRef(null);
  const NFTData = (data, setSelectedImagestate, extraData) => {
    return (
      data?.length > 0 &&
      data?.map((nft, index) => {
        return (
          <SwiperSlide
            key={index}
            onClick={() => {
              setSelectedImagestate(nft);
            }}
          >
            {nft?.file_type?.includes("audio/") ? (
              <div className={`${styles.audioSlide}`}>
                <div>
                  {!isSafari &&
                  browserName !== "Safari" &&
                  browserName !== "none" ? (
                    <Image
                      src={
                        extraData?.thumbnail?.home_page_listings ??
                        "/assets/img/image-loader.gif"
                      }
                      onClick={() => {
                        setSelectedImagestate(nft);
                      }}
                      alt={nft?.alt_text}
                      key={index}
                      className={`cursor-pointer`}
                      height="90px"
                      width="111px"
                    />
                  ) : (
                    <img
                      src={
                        extraData?.thumbnail?.original ??
                        "/assets/img/image-loader.gif"
                      }
                      onClick={() => {
                        setSelectedImagestate(nft);
                      }}
                      alt={nft?.alt_text}
                      key={index}
                      className={`cursor-pointer`}
                      height="90px"
                      width="111px"
                      
                    />
                  )}
                </div>
                <div className="NftTitle">
                  <p>{truncateString(nft?.title ?? "", 30)}</p>
                </div>
              </div>
            ) : nft?.file_type?.includes("video/") ? (
              <div
                className={`${styles.videoWrapperSlider}`}
                onClick={() => {
                  setSelectedImagestate(nft);
                }}
              >
                <div>
                  <video
                    src={`${nft?.src && nft?.src?.home_page_listings}`}
                    // controls
                    height="72px"
                    width="72px"
                    playsInline
                    autoPlay
                    loop
                    controlsList="nodownload"
                  />
                </div>
                <div className="NftTitle">
                  <p>{truncateString(nft?.title ?? "", 30)}</p>
                </div>
              </div>
            ) : !nft?.src?.home_page_listings.includes("pinata") ? (
              <>
                <div>
                  {!isSafari &&
                  browserName !== "Safari" &&
                  browserName !== "none" ? (
                    <Image
                      src={
                        nft?.src?.home_page_listings ??
                        "/assets/img/image-loader.gif"
                      }
                      onClick={() => {
                        setSelectedImagestate(nft);
                      }}
                      alt={nft?.alt_text}
                      key={index}
                      className={`cursor-pointer`}
                      height="90px"
                      width="111px"
                    />
                  ) : (
                    <img
                      src={nft?.src?.original ?? "/assets/img/image-loader.gif"}
                      onClick={() => {
                        setSelectedImagestate(nft);
                      }}
                      alt={nft?.alt_text}
                      key={index}
                      className={`cursor-pointer`}
                      height="90px"
                      width="111px"
                      
                    />
                  )}
                </div>
                <div className="NftTitle">
                  <p>{truncateString(nft?.title ?? "", 30)}</p>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div>
                  <Image
                    src={
                      nft?.src?.home_page_listings ??
                      "/assets/img/image-loader.gif"
                    }
                    onClick={() => {
                      setSelectedImagestate(nft);
                    }}
                    alt={nft?.alt_text}
                    key={index}
                    className={`cursor-pointer`}
                    height="90px"
                    width="111px"
                  />
                </div>
                <div className="NftTitle">
                  <p>{truncateString(nft?.title ?? "", 30)}</p>
                </div>
              </>
            )}
          </SwiperSlide>
        );
      })
    );
  };
  return (
    <div className={`${styles.sldier}`}>
      <div
        className={`${styles.buttonGroup}`}
        style={{ display: props?.item.length > 4 ? "block" : "none" }}
      >
        <span
          // onClick={props.onPrevious}
          ref={leftArrow}
          className={`${styles.leftArrow}`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#CCCCCC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          // onClick={props.onNext}
          ref={rightArrow}
          className={`${styles.rightArrow} ${
            props?.item.length > 3 ? "" : styles.rightArrowPosition
          }`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#CCCCCC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className={`detailsPageSliderCss`}>
        <Swiper
          spaceBetween={16}
          slidesPerView={4}
          navigation={{
            prevEl: leftArrow.current,
            nextEl: rightArrow.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = leftArrow.current;
            swiper.params.navigation.nextEl = rightArrow.current;
          }}
          freeMode={true}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {NFTData(props?.item, props.setSelectedImagestate, props.currentObj)}
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
